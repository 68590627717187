<template>
    <div class="order-detail">
        <div class="top">
            <router-link to="/user/orders/list">
                <i class="iconfont icon-papa-rt"></i>
                订单列表
            </router-link>
        </div>
        <div class="order-type">待支付</div>
        <div class="course-list">
            <div class="course-item" v-for="item in orderDetail.details" :key="item.id">
                <div class="course-pic">
                    <img :src=item.course.cover_image_url alt="">
                    <div class="course-desc">
                        <div class="course-name">{{ item.course.name }}</div>
                        <div class="course-subject">科目{{ item.course_subject }}</div>
                        <div class="course-teacher">教师{{ item.teacher_level }}</div>
                        <div class="course-price">￥{{ item.pay_fee }}</div>

                    </div>
                </div>
                <div class="course-qty">x{{ item.purchase_qty }}</div>
            </div>
        </div>
        <div class="order-desc">
            <div class="address desc-item" v-show="orderDetail.delivery&&orderDetail.delivery.district">
                <div class="key">收货地址</div>
                <div class="value" v-if="orderDetail.delivery">
                    {{ orderDetail.delivery.district }}{{ orderDetail.delivery.address }}
                </div>
                <i class="iconfont icontip"></i>
            </div>
            <div class="orderSn desc-item">
                <div class="key">订单编号</div>
                <div class="value">{{ orderDetail.order_sn }}</div>
            </div>
            <div class="createTime desc-item">
                <div class="key">创建时间</div>
                <div class="">{{ formatMoment(orderDetail.created_at) }}</div>

            </div>
        </div>
        <div class="money ">
            <div class="total desc-item">
                <div class="key">课程总价</div>
                <div class="value">￥{{ orderDetail.total_fee }}</div>
            </div>
            <div class="discount desc-item">
                <div class="key">课程优惠</div>
                <div class="value">￥{{ orderDetail.total_fee - orderDetail.pay_fee }}</div>
            </div>

            <div class="payTotal desc-item">
                <div class="key">实付款</div>
                <div class="value">￥{{ orderDetail.pay_fee }}</div>
            </div>
        </div>
        <div class="service" @click="choosePact">
            <i class="iconfont" :class="chooseService?'icon-papa-buy_selected':'icon-papa-buy_select_none'"></i>
            <span>我已同意</span>
            <router-link to="/terms">
                <span class="service-page">《趴趴英语服务协议》</span>
            </router-link>
        </div>
        <div class="section page-type">
            <div class="section-name">支付方式</div>
            <div class="type-list">
                <div class="ali-pay type" :class="activeType === PAY_TYPE.ALIPAY?'type-active':''"
                     @click="choosePayType(PAY_TYPE.ALIPAY)">
                    <img src="../img/alipay.png" alt="">
                    <i class="iconfont icon-papa-buy_angle_select" v-show="activeType === PAY_TYPE.ALIPAY"></i>
                </div>
                <div class="wechat-pay type" :class="activeType === PAY_TYPE.WECHAT?'type-active':''"
                     @click="choosePayType(PAY_TYPE.WECHAT)">
                    <img src="../img/wechat.png" alt="">
                    <i class="iconfont icon-papa-buy_angle_select" v-show="activeType === PAY_TYPE.WECHAT"></i>
                </div>
<!--                <div class="jdpay type" :class="activeType === 'jdPay'?'type-active':''"-->
<!--                     @click="choosePayType('jdPay')">-->
<!--                    <img src="../img/jdpay.png" alt="">-->
<!--                    <i class="iconfont icon-papa-buy_angle_select" v-show="activeType === 'jdPay'"></i>-->
<!--                </div>-->
            </div>
        </div>
        <div class="buy-box">
            <div class="buy-button" :class="chooseService? 'buy-button-active':''" @click="createOrder">去支付</div>
        </div>
        <div class="pay-code" v-show="qrcodeDialog">
            <div class="header">
                <div class="title">{{ payType }}支付</div>
                <div class="close-btn" @click="closeQrcode(1)">x</div>
            </div>
            <div class="content">
                <div class="pay-number">
                    <div class="key">应付金额</div>
                    <div class="value">￥{{ orderDetail.pay_fee }}</div>
                </div>
                <div id="qrcode" ref="qrcodeBox"></div>
            </div>
            <div class="footer">
                <div class="desc">使用{{ payType }}扫描二维码完成支付</div>
            </div>
        </div>
        <div class="mask" v-show="qrcodeDialog" @click="closeQrcode(1)"></div>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { userOrder } from '@/api/user/user.js'
import * as Api from '@/api/cart/cart'
import Pay from '@/utils/pay'
import { ORDER_TYPE, PAY_TYPE } from '@/enums/API'
import { formatMoment } from '@/utils/utils'


export default {
    name: 'Detail',
    data () {
        return {
            orderDetail: {},
            activeType: PAY_TYPE.ALIPAY,
            qrcodeDialog: false,
            chooseService: true,
            wsInterval: '',
            qrCodeUrl: '',
            websocketMsg: '',
            PAY_TYPE,
        }
    },
    mounted () {
        this.getOrder()
    },
    computed: {
        payType () {
            switch (this.activeType) {
                case this.PAY_TYPE.WECHAT:
                    return '微信'
                case this.PAY_TYPE.ALIPAY:
                    return '支付宝'
                default:
                    return '微信'
            }
        }
    },
    methods: {
        getOrder () {
            let orderSn = this.$route.query.order_sn
            userOrder('get', '', (res) => {
                this.orderDetail = res
            }, '', 'v1', orderSn)
        },
        choosePayType (type) {
            this.activeType = type
        },
        createOrder () {
            if (this.chooseService) {
                let self = this
                let params = {
                    order_sn: this.$route.query.order_sn,
                    order_type: ORDER_TYPE.COURSE,
                }
                let pay = new Pay(this.activeType, params)
                pay.pay((res) => {
                    self.$refs.qrcodeBox.innerHTML = ''
                    self.qrCode(res.order_info)
                    self.qrcodeDialog = true
                    self.initWS()
                    try {
                        this.wsInterval = setInterval(function () {
                            self.send()
                            let data = JSON.parse(self.websocketMsg)
                            if (data) {
                                switch (data.status) {
                                    case 1:
                                        console.log('未支付')
                                        break
                                    case 2:
                                        console.log('支付成功')
                                        self.$message.success('支付成功~')
                                        self.closeQrcode(2)
                                        clearInterval(self.wsInterval)
                                        break
                                    case 3:
                                        console.log('订单不存在')
                                        self.$message.error('订单不存在，请联系客服~')
                                        self.closeQrcode(3)
                                        clearInterval(self.wsInterval)
                                        break
                                    default:
                                        break
                                }
                            }
                        }, 5000)
                    } catch (e) {
                        clearInterval(this.wsInterval)
                    }
                    console.log('success')
                }, () => {
                    console.log('cancel')
                }, (error) => {
                    console.log('error', error)
                })
            } else {
                return
            }
        },
        qrCode (text) {
            return new QRCode('qrcode', {
                text: text,
                width: 120,
                height: 120,
                colorDark: '#000000',
                colorLight: '#ffffff',
            })
        },
        closeQrcode (status) {
            this.qrcodeDialog = false
            this.socket.close()
            clearInterval(this.wsInterval)
            if (status === 2) {
                this.$router.push({
                    path: '/user/course',
                })
            } else {
            }
        },
        choosePact () {
            this.chooseService = !this.chooseService
        },
        initWS () {
            this.socket = new WebSocket(Api.orderStatus)
            this.socket.onopen = this.wsOpen
            this.socket.onerror = this.wsError
            this.socket.onmessage = this.wsMessage
            this.socket.onclose = this.close
        },
        wsOpen () {
            console.log('连接成功')
        },
        wsError () {
            console.log('连接错误')
        },
        wsMessage (msg) {
            this.websocketMsg = msg.data
            return msg
        },
        send () {
            let params = {
                order_sn: this.$route.query.order_sn
            }
            params = JSON.stringify(params)
            this.socket.send(params)
        },
        close () {
            this.socket = null
            console.log('close')
        },
      formatMoment

    }
}
</script>

<style lang="less" scoped>
.order-detail {
    & > .top {
        padding-bottom: 20px;
        border-bottom: 1px solid #e6e6e6;
        text-align: left;
        font-size: 16px;
        color: #73797F;
        font-weight: bold;
        position: relative;
    }

    & > .order-type {
        font-size: 16px;
        color: #333;
        font-weight: bold;
        text-align: left;
        margin: 20px 0;
    }

    & > .course-list {
        & > .course-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            & > .course-pic {
                display: flex;

                & > img {
                    width: 144px;
                    height: 80px;
                    margin-right: 15px;
                }

                & > .course-desc {
                    text-align: left;
                    color: #555;
                    font-size: 14px;

                    & > .course-name {
                        font-size: 16px;
                        font-weight: bold;
                        color: #333;
                        margin-bottom: 8px;
                    }

                    & > .course-price {
                        color: #FF5864;
                        font-size: 18px;
                        font-weight: bold;
                    }

                }
            }
        }
    }

    & > .order-desc {
        margin-bottom: 15px;

        & > .desc-item {
            display: flex;

            & > .key {
                text-align: left;
                width: 4em;
                margin-right: 10px;
            }
        }

        & > .address {
            position: relative;

            & > .icontip {
                position: absolute;
                right: 0;
            }
        }
    }

    & > .money {
        width: 100%;
        background: #F7F7F7;
        box-sizing: border-box;
        padding: 20px;

        & > .orderTotal {
            color: #666;
            font-weight: bold;
        }

        & > .payTotal {
            color: #666;
            font-size: 16px;
            font-weight: bold;

            & > .value {
                color: #FF5864;
            }
        }

        & > .desc-item {
            display: flex;
            margin-bottom: 6px;
            align-items: center;

            & > .key {
                text-align: left;
                width: 4em;
                margin-right: 10px;
                font-size: 12px;
            }

            & > .value {
                font-size: 16px;
            }
        }
    }

    & > .service {
        margin-top: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        text-align: left;
        cursor: pointer;

        & > .icon-papa-buy_selected {
            color: #29D087;
        }

        & > a {
            & > .service-page {
                color: #29D087;
            }
        }
    }

    & > .page-type {
        margin-bottom: 50px;

        & > .section-name {
            width: 100%;
            text-align: left;
            font-size: 18px;
            font-family: PingFang-SC-Bold;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);
            margin: 20px 0;
        }

        & > .type-list {
            display: flex;

            & > .type {
                cursor: pointer;
                width: 142px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0;
                position: relative;
                border: 1px solid transparent;
            }

            & > .type-active {
                border: 1px solid #29D087 !important;

                & > .icon-papa-buy_angle_select {
                    position: absolute;
                    bottom: -2px;
                    right: -2px;
                    color: #29D087;
                }

                & > .icon-papa-buy_angle_select:before {
                    margin-right: 0;
                }
            }

            & > .ali-pay, .wechat-pay {
                margin-right: 50px;
            }
        }
    }

    & > .buy-box {
        float: right;

        & > .buy-button {
            width: 160px;
            height: 46px;
            background: #73797F;
            border-radius: 3px;
            color: #fff;
            text-align: center;
            line-height: 46px;
            font-size: 18px;
            cursor: pointer;
            margin-bottom: 25px;
        }

        & > .buy-button-active {
            background: rgba(41, 208, 135, 1);
        }
    }

    & > .pay-code {
        width: 280px;
        height: 300px;
        border-radius: 8px;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -170px;
        margin-left: -140px;
        z-index: 10000;

        & > .header {
            width: 100%;
            height: 50px;
            font-size: 15px;
            line-height: 50px;
            border-bottom: 1px solid #ccc;
            display: flex;
            justify-content: space-between;
            font-weight: 700;
            box-sizing: border-box;
            padding: 0 10px;

            & > .close-btn {
                cursor: pointer;
                width: 20px;
                height: 100%;
                font-weight: lighter;
                font-size: 16px;
            }
        }

        & > .content {
            & > .pay-number {
                display: flex;
                justify-content: center;
                margin: 10px 0;
                align-items: center;

                & > .value {
                    text-align: left;
                    font-size: 20px;
                    color: red;
                }
            }

            & > #qrcode {
                position: absolute;
                top: 58%;
                left: 50%;
                margin-top: -60px;
                margin-left: -60px;
            }
        }

        & > .footer {
            & > .desc {
                width: 100%;
                position: absolute;
                bottom: 20px;
                margin-top: 10px;
                font-size: 12px;
                text-align: center !important;
            }
        }

    }

    & > .mask {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.2);
    }

}

</style>
